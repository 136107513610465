export default {
	tablet: {
		px: '767px',
		num: 767,
	},
	sdesk: {
		// small desktop
		px: '1112px',
		num: 1112,
	},
	ldesk: {
		// large desktop
		px: '1480px',
		num: 1480,
	},
}
